/*
=============== 
Variables
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --clr-primary: #645cff;
  --clr-primary-dark: #282566;
  --clr-primary-light: #a29dff;
  --clr-grey-1: #102a42;
  --clr-grey-5: #617d98;
  --clr-grey-10: #bfe9f3;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.25rem;
  --large-screen-width: 1370px;
  --small-screen-width: 90vw;
  --fixed-width: 50rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
html,
body {
  font-family: 'Amazon Ember';
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 360px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.25;
    font-weight: 800;
    text-align: center;
    background-color: rgb(202, 192, 179);
  }
  h5 {
    font-size: 1rem;
    text-transform: uppercase;
    line-height: 1.25;
    font-weight: 800;
    text-align: center;
    background-color: rgb(202, 192, 179);
  }
  .combo-details-h6 {
    font-size: 0.8rem;
    text-transform: uppercase;
    line-height: 1.25;
    font-weight: 800;
    text-align: center;
    background-color: rgb(202, 192, 179);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1;
  }
}
/* more global css */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.btn-amount {
  width: 5rem;
}
.btn:hover {
  color: var(--clr-primary);
  background: var(--clr-primary-light);
}

/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  background-color: #232f3e;
  padding: 1rem 1rem;
}

.navbar {
  padding: 0.1rem 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

#search_btn {
  background-color: #febd69;
}

.input-group {
  width: 90%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn,
#new_review_btn {
  background-color: #fa9c23;
  padding: 0.4rem 1.8rem;
  color: white;
}

#cart {
  font-size: 1rem;
  color: white;
}

#cart_count {
  background-color: #febd69;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
}

/* product-card */
.pcard {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 25% auto;
  /* grid-column-gap: .5rem; */
  margin: 0.5rem 0;
  /* border: #102a42; */
  margin-bottom: 2rem;
  background-color: var(--clr-grey-10);
  padding: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.pcardimg {
  width: auto;
  height: auto;
  /* object-fit: cover; */
  border-radius: 1rem;
}

.pcardbody {
  border: #102a42;
  margin-left: 1rem;
}
.pcardheader {
  width: 100%;
  height: 20%;
  margin-bottom: 1rem;
  font-weight: bold;
}
.pcardname {
  float: left;
  width: 50%;
  text-transform: uppercase;
  margin-right: 2rem;
}

.pcardprice {
  float: left;
}

.pcarddescr {
  font-size: 0.9rem;
  width: 100%;
  height: auto;
  float: left;
  text-transform: capitalize;
}

/* combo-card */
.combocard {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 25% auto;
  /* grid-column-gap: .5rem; */
  margin: 0.5rem 0;
  /* border: #102a42; */
  margin-bottom: 2rem;
  background-color: var(--clr-grey-10);
  padding: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.combocardimg {
  width: auto;
  height: auto;
  /* object-fit: cover; */
  border-radius: 1rem;
}

.combocardbody {
  border: #102a42;
  margin-left: 1rem;
}
.combocardheader {
  width: 100%;
  height: 20%;
  margin-bottom: 1rem;
  font-weight: bold;
}
.combocardname {
  float: left;
  width: 50%;
  text-transform: uppercase;
  margin-right: 2rem;
}

.combocardprice {
  float: left;
}

.combocarddescr {
  font-size: 0.9rem;
  width: 100%;
  height: auto;
  float: left;
  text-transform: capitalize;
}
.cart-card {
  display: grid;
  grid-template-columns: auto;
  border-color: black;
  border-style: solid;
  border-radius: 0.9rem;
  padding: 1rem;
  margin: auto;
}

.cart-message {
  display: grid;
  grid-template-columns: auto;
  text-transform: capitalize;
  font-weight: bold;
  /* margin-top: .5rem; */
  margin-bottom: 0.5rem;
  background-color: rgb(218, 203, 203);
  border-color: black;
  border-style: solid;
  font-size: 1rem;
  padding: 0.3rem;
}

.cart-total-row {
  display: grid;
  grid-template-columns: auto;
  text-transform: capitalize;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgb(218, 203, 203);
  border-color: black;
  border-style: solid;
  font-size: 1rem;
  padding: 0.3rem;
}
.cart-header-row {
  display: grid;
  grid-template-columns: auto 35%;
  text-transform: capitalize;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgb(218, 203, 203);
  border-color: black;
  border-style: solid;
  font-size: 1rem;
  padding: 0.3rem;
}
.cart-meal-card {
    border-color: black;
  border-style: solid;
  margin-bottom: 0.5rem;
}
.cart-meal-row {
  display: grid;
  grid-template-columns: auto 35%;
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 1rem;
}
/* my-orders-page */
.order-card{
    display: grid;
  grid-template-columns: auto;
  text-transform: capitalize;
  font-weight: bold;
  border: 1px solid black;
   border-radius: 1rem;
   margin-bottom: 1rem;
   margin-top: 1rem;
   padding:1rem;
}

.status {
  font-size: 1.2rem;
  background-color: darkgrey;
  border: 1px solid black;
  border-radius: 1rem;
}

.heading-{
    display: grid;
  grid-template-columns: auto auto;
  text-transform: capitalize;
  font-weight: bold;
}
.heading-col{
    display: grid;
  grid-template-columns: auto;
  text-transform: capitalize;
  font-weight: bold;
}
.total-col{
    display: grid;
  grid-template-columns: auto;
  text-transform: capitalize;
  font-weight: bold;
}
.extraCard {
  display: grid;
  /* align-items: center; */
  grid-template-columns: 300px auto 300px;
  /* grid-column-gap: .5rem; */
  margin: 0.5rem 0;
  /* border: #102a42; */
  margin-bottom: 2rem;
  background-color: var(--clr-grey-10);
  padding: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.headingRow {
  display: grid;

  grid-template-columns: auto auto auto auto;
  grid-column-gap: 0.5rem;
  margin: 0.5rem 0;
  border: #102a42;
  margin-bottom: 0.5rem;
  background-color: rgb(56, 35, 35);
  padding: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  color: #bfe9f3;
  font-weight: 800;
  font-size: large;
  text-transform: uppercase;
}
.menuCard {
  display: grid;
  /* align-items: center; */
  grid-template-columns: auto;
  /* grid-column-gap: .5rem; */
  margin: 0.5rem 0;
  /* border: #102a42; */
  margin-bottom: 0.5rem;
  background-color: var(--clr-grey-10);
  padding: 1rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

.menuCardBody {
  display: grid;
  /* align-items: center; */
  grid-template-columns: auto 10%;
  border: #102a42;
  margin-left: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  background-color: var(--clr-grey-10);
  padding: 1rem;
}
.comboMealCard {
  display: grid;
  /* align-items: center; */
  grid-template-columns: auto auto 10%;
  border: #102a42;
  margin-left: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 4rem;
  margin-bottom: 1rem;
  align-items: center;
  background-color: var(--clr-grey-10);
}
.menuCardRow {
  display: grid;
  /* align-items: center; */
  grid-template-columns: auto 10%;
  border: #102a42;
  margin-left: 1rem;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.9rem;
  margin-right: 2rem;
}
.comboDesc {
  text-transform: capitalize;
  text-align: justify;
}
.counter {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-between;
}

.btn-plus,
.btn-minus {
  text-transform: uppercase;
  background: rgb(28, 45, 49);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  font-size: 1.1rem;
  border-radius: 5cap;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.count {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 900;
  margin: 0 0.5rem;
  border: black;
  width: 1rem;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Footer */
footer {
  margin-top: auto;
  color: grey;
  bottom: 0;
  width: 100%;
}

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #fa9c23;
  border: none;
  padding: 0.5rem 2rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red !important;
}

.greenColor {
  color: green !important;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  font-weight: bold;
  color: black;
  font-size: 1rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_name {
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
}
#card_item_price {
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  margin-top: 2rem;
  border-radius: 5rem;
}

#view_order_details {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 1rem 1rem;
}

.order-summary-values {
  font-weight: bold;
  color: black;
  font-size: 1rem;
}

.stockCounter input {
  border: black;
  width: 3rem;
  text-align: center;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 2;
}

.form-control:disabled,
.form-control[readOnly] {
  background-color: white;
}
.form-check-input {
  background-color: black;
  outline: black;
  box-shadow: black;
}
/* Login & Register */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-weight: 700;
}

.wrapper form {
  padding: 2.5rem 3rem;
}

.wrapper form .btn,
.fetch-btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  color: white;
  margin-top: 1rem;
}

.fetch-btn:hover {
  background-color: #fa9c23;
  color: white;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #fa9c23;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #fa9c23;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #fa9c23;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #fa9c23;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn,
#create_btn {
  background-color: #fa9c23;
  border-color: #fa9c23;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #fa9c23;
  border-color: #fa9c23;
}

.page-link {
  color: #fa9c23;
}

.page-link:hover {
  color: #fa9c23;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.admin-child-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/* Sidebar */

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item {
  border: none;
}

.list-group-item.active {
  background-color: #f3f4f6;
  border-color: #f3f4f6;
  border-radius: 0.3rem;
  color: #fa9c23;
}

/*
=============== 
Navbar
===============
*/
.loading {
  text-align: center;
  margin-top: 5rem;
}
nav {
  background: var(--clr-primary);
  padding: 1.25rem 2rem;
}
.nav-center {
  max-width: var(--fixed-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav h6 {
  margin-bottom: 0;
  letter-spacing: 1px;
  color: var(--clr-white);
}
.nav-container {
  display: block;
  position: relative;
}
nav svg {
  width: 40px;
  color: var(--clr-white);
}
.amount-container {
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: var(--clr-primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
.total-amount {
  color: var(--clr-white);
  margin-bottom: 0;
  font-size: 1.25rem;
}
/*
=============== 
Cart
===============
*/
.cart {
  min-height: calc(100vh - 120px);
  width: 90vw;
  margin: 0 auto;
  margin-top: 40px;
  padding: 2.5rem 0;
  max-width: var(--fixed-width);
}
.cart h2 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
}
.empty-cart {
  text-transform: lowercase;
  color: var(--clr-grey-5);
  margin-top: 1rem;
  text-align: center;
}
.cart footer {
  margin-top: 4rem;
  text-align: center;
}
.cart-total h4 {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.clear-btn,
.confirm-btn {
  background: transparent;
  padding: 0.5rem 1rem;
  color: var(--clr-red-dark);
  border: 1px solid var(--clr-red-dark);
  margin-top: 2.25rem;
  border-radius: var(--radius);
}
.clear-btn:hover {
  background: var(--clr-red-light);
  color: var(--clr-red-dark);
  border-color: var(--clr-red-light);
}
.confirm-btn {
  border-color: var(--clr-primary);
  color: var(--clr-primary);
}
/*
=============== 
Cart Item
===============
*/
.cart-item {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1.5rem;
  margin: 1.5rem 0;
}
.cart-item img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}
.cart-item h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  letter-spacing: 2px;
}
.item-price {
  color: var(--clr-grey-5);
}
.remove-btn {
  color: var(--clr-primary);
  letter-spacing: var(--spacing);
  cursor: pointer;
  font-size: 0.85rem;
  background: transparent;
  border: none;
  margin-top: 0.375rem;
  transition: var(--transition);
}
.remove-btn:hover {
  color: var(--clr-primary-light);
}
.amount-btn {
  width: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.amount-btn svg {
  color: var(--clr-primary);
}
.amount-btn:hover svg {
  color: var(--clr-primary-light);
}
.amount {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1;
}
hr {
  background: var(--clr-grey-5);
  border-color: black;
  border-width: 1.25px;
}
